 <template>
  <div class="footer_wrap">
    <!-- Footer Start -->
    <div class="flex-grow-1"></div>
    <div class="app-footer">
      <div class="row">
        <div class="col-md-9">
          <p><strong>GHub - Comfort Living</strong></p>
          <p>
            Dit is een onderdeel van het ecosysteem van GHub.
          </p>
        </div>
      </div>
      <div
        class="footer-bottom border-top pt-3 d-flex flex-column flex-sm-row align-items-center"
      >
        <div class="d-flex align-items-center">
          <img class="logo" src="@/assets/images/logo-inverse.png" alt="" />
          <div>
            <div>
              <p class="m-0">&copy; 2021 GHub</p>
              <p class="m-0">Alle rechten voorbehouden</p>
            </div>
          </div>
          <span class="flex-grow-1"></span>
          <a
            class="btn btn-primary text-white btn-rounded"
            href="https://gbdf.it/"
            target="_blank"
            >Bekijk GBDF.it</a
          >
        </div>
      </div>
      <!-- fotter end -->
    </div>
  </div>
</template> 
<script>
export default {
  data() {
    return {};
  },
  methods: {}
};
</script>


<style lang="scss" scoped>
</style> 